<template>
  <div id="nav" class="flex flex-row h-20 p-4 items-start">
    <!-- <div class="h-full"> -->
    <!-- remove enclosing div to put logo inline in the bar -->
    <img src="../assets/logo.png" class="h-full" />
    <!-- </div> -->
    <Socials class="flex-1 justify-end h-full" />
  </div>
</template>

<script>
import Socials from "./Socials.vue";

export default {
  name: "TopBar",
  components: {
    Socials,
  },
};
</script>
