<template>
  <div id="socials" class="flex flex-row gap-2 min-w-max">
    <a href="https://opensea.io/collection/nekocore">
      <img src="../assets/socials/opensea.svg" />
    </a>
    <a href="https://twitter.com/NekoCoreNFT"
      ><img src="../assets/socials/twitter.svg"
    /></a>
    <a href="https://discord.gg/nekocore">
      <img src="../assets/socials/discord.svg"
    /></a>
    <Web3></Web3>
  </div>
</template>

<script>
import Web3 from './Web3.vue';
export default {
  components: {Web3},
  name: "Socials",
};
</script>
