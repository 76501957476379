<template>
  <button
    :class="connectionStateStyling"
    class="hidden md:block bg-transparent font-semibold py-2 px-4 rounded"
    @click="connect"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: "Web3",
  data: function () {
    return {
      eth: this.$root.$data.eth,
    };
  },
  props: {},
  computed: {
    connectionStateStyling: function () {
      if (this.eth?.selectedAddress) {
        if (this.eth.chainId === "0x1") {
          return "cursor-default text-green-700 border border-green-500";
        } else {
          return "cursor-default text-yellow-700 border border-yellow-500";
        }
      } else {
        return "hover:text-white hover:border-transparent hover:bg-blue-500 text-blue-700 border border-blue-500";
      }
    },
    buttonText: function () {
      if (typeof this.eth === "undefined") {
        return "Get MetaMask";
      } else if (this.eth.selectedAddress) {
        let acc = this.eth.selectedAddress;
        let top = acc.slice(0, 5);
        let bot = acc.slice(acc.length - 4);
        return `${top}...${bot}`;
      } else {
        return "Connect";
      }
    },
  },
  methods: {
    connect: function () {
      if (typeof this.eth === "undefined") {
        window.open("https://metamask.io/");
      } else if (this.eth.selectedAddress) {
        console.log(`${this.eth.selectedAddress} already connected`);
      } else {
        this.eth.request({
          method: "eth_requestAccounts",
        }).catch(err => console.log(err));
      }
    },
  },
};
</script>
